import { useQuery } from "react-query";
import { apiEmpleo } from "../../../../../../util/ApiEmpleo";
import {
  TIPO_EXTERNA_ENTRE_SEMANA,
  TIPO_EXTERNA_FIN_DE_SEMANA,
  TIPO_INTERNA_ENTRE_SEMANA,
  TIPO_INTERNA_FIN_DE_SEMANA,
} from "../../../../../../util/defaults/contrato";

export const parseQueryParams = (employeesPageFilters: any) => {
  const params = {} as any;

  if (
    employeesPageFilters.businessCountry === "es" &&
    employeesPageFilters.provincia !== ""
  ) {
    params.provincia = employeesPageFilters.provincia;
  }
  if (employeesPageFilters.lugarDeNacimiento !== "") {
    params.lugarDeNacimiento = employeesPageFilters.lugarDeNacimiento;
  }
  if (
    employeesPageFilters.businessCountry === "fr" &&
    employeesPageFilters.department !== ""
  ) {
    params.department = employeesPageFilters.department;
  }
  if (employeesPageFilters.businessCountry === "fr") {
    if (employeesPageFilters.titulacion === "si") {
      params["exists[titulacionOficialSAD]"] = "true";
    } else if (employeesPageFilters.titulacion === "no") {
      params["exists[titulacionOficialSAD]"] = "false";
    } else if (employeesPageFilters.titulacion !== "") {
      params.hasDegrees = employeesPageFilters.titulacion;
    }
  } else {
    if (employeesPageFilters.titulacion === "si") {
      params["exists[titulacionOficialSAD]"] = "true";
    }
    if (employeesPageFilters.titulacion === "no") {
      params["exists[titulacionOficialSAD]"] = "false";
    }
  }
  if (employeesPageFilters.sexo !== "") {
    params.sexo = employeesPageFilters.sexo;
  }
  if (employeesPageFilters.estadoCuideo.length) {
    params.estadoCuideo = employeesPageFilters.estadoCuideo;
  }
  if (employeesPageFilters.felizVitaStatus.length) {
    params.felizVitaStatus = employeesPageFilters.felizVitaStatus;
  }
  if (employeesPageFilters.carnet !== "") {
    params.carnetDeConducir = employeesPageFilters.carnet;
  }
  if (employeesPageFilters.vehiculo !== "") {
    params.vehiculoPropio = employeesPageFilters.vehiculo;
  }
  params.age = [];
  if (employeesPageFilters.edad.includes("18-25")) {
    params.age = [...params.age, ...[18, 19, 20, 21, 22, 23, 24, 25]];
  }
  if (employeesPageFilters.edad.includes("26-35")) {
    params.age = [...params.age, ...[26, 27, 28, 29, 30, 31, 32, 33, 34, 35]];
  }
  if (employeesPageFilters.edad.includes("36-45")) {
    params.age = [...params.age, ...[36, 37, 38, 39, 40, 41, 42, 43, 44, 45]];
  }
  if (employeesPageFilters.edad.includes("46-55")) {
    params.age = [...params.age, ...[46, 47, 48, 49, 50, 51, 52, 53, 54, 55]];
  }
  if (employeesPageFilters.edad.includes("56-60")) {
    params.age = [...params.age, ...[56, 57, 58, 59, 60]];
  }
  if (employeesPageFilters.edad.includes("61-65")) {
    params.age = [...params.age, ...[61, 62, 63, 64, 65]];
  }
  if (employeesPageFilters.edad.includes("+65")) {
    params.age = [...params.age, ...[66, 67, 68, 69, 70]];
  }
  if (employeesPageFilters.experience.length) {
    params.yearsOfExperience = employeesPageFilters.experience;
  }
  if (employeesPageFilters.tasks.length) {
    params.hasTasks = employeesPageFilters.tasks.join(",");
  }
  if (employeesPageFilters.languages.length) {
    params.hasLanguages = employeesPageFilters.languages.join(",");
  }
  if (employeesPageFilters.pathologies.length) {
    params.hasPathologies = employeesPageFilters.pathologies.join(",");
  }
  if (employeesPageFilters.workRegime.length) {
    params.hasWorkRegime = employeesPageFilters.workRegime.join(",");
  }
  if (employeesPageFilters.interviewStatus.length) {
    params.interviewStatus = employeesPageFilters.interviewStatus;
  }
  if (employeesPageFilters.businessCountry !== "") {
    params.businessCountry = employeesPageFilters.businessCountry;
  }
  if (employeesPageFilters.inOpenProcess === "no") {
    params["numOpenValuationProcesses[lt]"] = 1;
  }
  if (employeesPageFilters.inOpenProcess === "yes") {
    params["numOpenValuationProcesses[gte]"] = 1;
  }
  if (employeesPageFilters.inRecentProcesses === "last7") {
    params["numDaysSinceEnteredValuationProcess[lte]"] = 7;
  }
  if (employeesPageFilters.inRecentProcesses === "last14") {
    params["numDaysSinceEnteredValuationProcess[lte]"] = 14;
  }
  if (employeesPageFilters.inRecentProcesses === "last30") {
    params["numDaysSinceEnteredValuationProcess[lte]"] = 30;
  }

  if (employeesPageFilters.hasWhatsapp === "yes") {
    params.hasWhatsapp = 1;
  } else if (employeesPageFilters.hasWhatsapp === "no") {
    params.hasWhatsapp = 0;
  }

  if (employeesPageFilters.botAvailable === "yes") {
    params.botAvailable = 1;
  } else if (employeesPageFilters.botAvailable === "no") {
    params.botAvailable = 0;
  }

  if (employeesPageFilters.company === "FV") {
    params.company = "FV";
  }
  if (employeesPageFilters.company === "Cuideo") {
    params["company[exists]"] = 0;
  }

  if (employeesPageFilters.search !== "") {
    params.searchFor = employeesPageFilters.search;
  }
  if (employeesPageFilters.orden === "recientes") {
    params["order[createdAt]"] = "desc";
  }
  if (employeesPageFilters.orden === "antiguas") {
    params["order[createdAt]"] = "asc";
  }
  if (employeesPageFilters.tipo.includes(TIPO_INTERNA_ENTRE_SEMANA)) {
    params["internaEntreSemana"] = 1;
  }
  if (employeesPageFilters.tipo.includes(TIPO_INTERNA_FIN_DE_SEMANA)) {
    params["internaFinSemana"] = 1;
  }
  if (employeesPageFilters.tipo.includes(TIPO_EXTERNA_ENTRE_SEMANA)) {
    params["externaEntreSemana"] = 1;
  }
  if (employeesPageFilters.tipo.includes(TIPO_EXTERNA_FIN_DE_SEMANA)) {
    params["externaFinSemana"] = 1;
  }
  if (employeesPageFilters.enMapa === "si") {
    if (employeesPageFilters["latitud[gt]"]) {
      params["latitud[gt]"] = employeesPageFilters["latitud[gt]"];
    }
    if (employeesPageFilters["latitud[lt]"]) {
      params["latitud[lt]"] = employeesPageFilters["latitud[lt]"];
    }
    if (employeesPageFilters["longitud[gt]"]) {
      params["longitud[gt]"] = employeesPageFilters["longitud[gt]"];
    }
    if (employeesPageFilters["longitud[lt]"]) {
      params["longitud[lt]"] = employeesPageFilters["longitud[lt]"];
    }
  }
  if (employeesPageFilters.availability) {
    params["availability"] = employeesPageFilters.availability;
  }

  return params;
};

export function useEmployeesQuery(
  employeesPageFilters: any,
  filtersReady: boolean,
  itemsPerFirstQuery: number
) {
  const queryFilters = { ...employeesPageFilters };
  if (employeesPageFilters.enMapa === "no") {
    delete queryFilters["latitud[gt]"];
    delete queryFilters["latitud[lt]"];
    delete queryFilters["longitud[gt]"];
    delete queryFilters["longitud[lt]"];
  }
  delete queryFilters.selectedEmployeeId;
  delete queryFilters.eTab;
  return useQuery(
    ["affinityEmployees", queryFilters],
    async () => {
      const params = parseQueryParams(employeesPageFilters) as any;
      params.itemsPerPage = queryFilters.page === 1 ? itemsPerFirstQuery : 10;
      params.page = employeesPageFilters.page;
      params.partial = 1;

      let res = await apiEmpleo.get("candidatos", { params });
      return {
        employees: res["hydra:member"],
        totalItems: res["hydra:totalItems"],
        page: params.page,
      };
    },
    {
      enabled: filtersReady,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    }
  );
}

export function useEmployeesMapQuery(employeesPageFilters: any) {
  const queryFilters = { ...employeesPageFilters };
  if (employeesPageFilters.enMapa === "no") {
    delete queryFilters["latitud[gt]"];
    delete queryFilters["latitud[lt]"];
    delete queryFilters["longitud[gt]"];
    delete queryFilters["longitud[lt]"];
  }
  delete queryFilters.page;
  return useQuery(
    ["affinityEmployeesMap", queryFilters],
    async () => {
      const params = parseQueryParams(employeesPageFilters) as any;
      params.itemsPerPage = 200;
      params.page = 1;

      let res = await apiEmpleo.get("candidatos", { params });
      return {
        employees: res["hydra:member"],
        totalItems: res["hydra:totalItems"],
      };
    },
    {
      enabled: false,
    }
  );
}
